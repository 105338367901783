<template>
    <div class="color-loadpoint">
        <h3><strong>Solicitud de carga de punto</strong></h3>
        <p class="form__success">
            El envío de tu solicitud de registro de puntos se completó. El administrador te comfirmará por correo electrónico o SMS la aprobación.
            Puede consultarse tambien en el "Registro histórico de puntos".
        </p>
        <h3><strong>Resumen de solicitud</strong></h3>
        <ul>

            <li v-if="data.typeUser == 'SHOP'">
                <strong>Tienda:</strong> {{ data.shop }}
            </li>
            <li v-else>
                <strong>Usuario:</strong> {{ data.name }}
            </li>
            <li><strong>Membresía ID:</strong> {{ data.membership }}</li>
            <li><strong>Nombres y Apellidos:</strong> {{ data.fullName }}</li>
            <!-- <li><strong>Linea de producto:</strong> {{ data.productForExchange }}</li> -->
            <!-- <li><strong>Cantidad en mts.:</strong> {{ data.meter }}</li> -->
            <!-- <li><strong>Puntos:</strong> {{ data.point }}</li> -->
            <li><strong>Fecha de solicitud:</strong> {{ data.date_register }}</li>
            <li><strong>Imagen de comprobante:</strong> {{ data.voucher }}</li>
            <li><strong>Estado:</strong> {{ data.status }}</li>

        </ul>
    </div>
    
</template>

<script>

export default {
    name: "ShopRegisterPointSuccess",    
    props: {
        data: Object
    }
}
</script>