<template>
  <main>
    <loading
      :active="isLoading"
      :can-cancel="false"
      :on-cancel="onCancel"
      :is-full-page="fullPage"
    />
    <HeadShop />
    <section class="section pt-0 my-status">
      <div class="container">
        <p class="breadcrumb">
          <a href="/">
            <span class="icon-home-outline"></span>
          </a> /
          <span>Registro de puntos</span>
        </p>
        <h2>
          <a href="#" @click="router.go(-1)">
            <img src="/images/arrowleft.svg" alt="Club de Sastreria Barrington" />
          </a> Registro de puntos
        </h2>

        <div class="page-content">
          <div class="tabbed">
            <input type="radio" id="tab1" name="css-tabs" checked />
            <input type="radio" id="tab2" name="css-tabs" />
            <ul class="tabs">
              <li class="tab">
                <label for="tab1">Registro de puntos</label>
              </li>
              <li class="tab">
                <label for="tab2">Registro histórico de puntos</label>
              </li>
            </ul>
            <div class="tab-content">
              <p>
                Instrucciones generales para el registro de puntos, para asignar puntos se busca el usuario por nombres, apellidos
                o documento de identidad.
              </p>

              <div class="form__control">
                <label
                  class="form__label"
                >Buscar Usuario por nombres, apellidos o documento de identidad:</label>

                <input
                  type="text"
                  class="form__field"
                  placeholder
                  v-model="formUserSearch.query"
                  @keyup.enter="getUser()"
                />
                <template v-if="formUserSearch.list.length > 0">
                  <p class="form__success">Se encontró la siguiente coincidencia</p>
                  <div class="table-normal">
                    <table class="table-normal__content">
                      <tr>
                        <th>Membresía ID</th>
                        <th>Nombres y Apellidos</th>
                        <th>Puntos acumulados</th>
                        <th>Accion</th>
                      </tr>
                      <tr v-for="(v, k) in formUserSearch.list" :key="k">
                        <td>{{ v.code_user }}</td>
                        <td>{{ v.first_name }} {{ v.last_name }}</td>
                        <td>{{ v.total_point }}</td>
                        <td>
                          <a href="#" @click="show" v-if="!showButtonLoadPoint">Cerrar</a>
                          <button
                            class="cta"
                            @click="loadPoint(v)"
                            v-if="showButtonLoadPoint"
                          >Cargar puntos</button>
                        </td>
                      </tr>
                    </table>
                  </div>

                  <br />
                  <form
                    action
                    class="form container container--xs color form_point_send"
                    enctype="multipart/form-data"
                    @submit.prevent="onSubmitLoadPoint"
                    v-if="!showButtonLoadPoint && !registerPointSuccess"
                  >
                    <h3>Solicitud de carga de puntos</h3>

                    <div class="form__control">
                      <label class="form__label">Linea de producto comprado :</label>
                      <select
                        class="form__field form__field--select"
                        v-model="formLoadPoint.product_for_exchange"
                        @change="selectedProductForExchange($event)"
                      >
                        <option value disabled>Seleccione linea de producto comprado</option>
                        <option
                          :value="tu.id"
                          v-for="(tu, k) in productForExchange"
                          :key="k"
                        >{{ tu.title }}</option>
                      </select>
                      <p
                        class="form__alert"
                        v-if="formLoadPointError.errors.product_for_exchange"
                      >{{ formLoadPointError.errors.product_for_exchange[0] }}</p>
                    </div>
                    <div class="form__control">
                      <label class="form_label">Cantidad de mts comprados</label>
                      <input type="text" class="form__field" v-model="formLoadPoint.meter" />
                      <span>
                        <router-link :to="{ name: 'HowEarnPoint' }" target='_blank'>Equivalencia de Puntos por metro de producto comprado aquí.</router-link>
                      </span>
                      <p
                        class="form__alert"
                        v-if="formLoadPointError.errors.meter"
                      >{{ formLoadPointError.errors.meter[0] }}</p>
                    </div>
                    <div class="form__control">
                      <label class="form__label">Suba imagen del comprobante de pago :</label>
                      <input
                        type="file"
                        accept="image/*"
                        class="form__field"
                        @change="uploadImageVoucher($event)"
                      />
                      <p
                        class="form__alert"
                        v-if="formLoadPointError.errors.voucher"
                      >{{ formLoadPointError.errors.voucher[0] }}</p>
                    </div>
                    <div class="form__control">
                      <label class="form__label">Fecha de solicitud :</label>
                      <p class="form__warning">
                        Importante
                        <br />Al registrar la fecha, recuerde que sólo se aceptará el reclamo de puntos si se realiza como
                        máximo un día después de la fecha indicada en la imagen del recibo o factura.
                      </p>
                      <!-- <input type="text" class="form__field" v-model="formLoadPoint.date_register"> -->
                      <Datepicker class="form__field" v-model="formLoadPoint.date_register" />

                      <p
                        class="form__alert"
                        v-if="formLoadPointError.errors.date_register"
                      >{{ formLoadPointError.errors.date_register[0] }}</p>
                    </div>
                    <div class="text-center mt-md">
                      <button class="cta">Enviar</button>
                    </div>
                  </form>
                  <ShopRegisterPointSuccess
                    :data="shopRegisterPointSuccessData"
                    v-if="registerPointSuccess"
                  />
                </template>
              </div>
            </div>
            <div class="tab-content">
              <div class="table-normal">
                <table class="table-normal__content">
                  <tr>
                    <th>&nbsp;</th>
                    <th>Código</th>
                    <th>Membresía ID</th>
                    <th>Nombres y Apellidos</th>
                    <th>Puntos</th>
                    <th>Estado</th>
                    <th>Registro</th>
                  </tr>
                  <tr v-for="(v, k) in pointActivityData" :key="k">
                    <td>+</td>
                    <td>{{ v.id }}</td>
                    <td>{{ v.user.code_user }}</td>
                    <td>{{ v.user.first_name }} {{ v.user.last_name }}</td>
                    <td>{{ Math.ceil(Math.random()*100) }}</td>
                    <td>
                      <span :class="'label ' + getCssStatus(v.status)">{{ v.status_label }}</span>
                    </td>
                    <td>{{ v.date_register }}</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { ref } from "vue";
import Datepicker from "vue3-datepicker";
import router from "@/router";
import API from "@/api";
import store from "@/store";
import HeadShop from "@/views/private/shop/components/HeadShop";
import ShopRegisterPointSuccess from "@/views/private/shop/components/ShopRegisterPointSuccess";

export default {
  name: "ShopRegisterShop",
  components: {
    HeadShop,
    ShopRegisterPointSuccess,
    Datepicker,
    Loading
  },
  setup() {
    var isLoading = ref(false);
    const fullPage = true;

    const formUserSearch = ref({
      query: "",
      password: "",
      list: []
    });

    const userData = ref({});
    const productForExchangeSelectedText = ref("");
    const formLoadPoint = ref({
      product_for_exchange: "",
      meter: "",
      voucher: null,
      shop: store.state.dataToken.shop,
      user: "",
      type_point: "REGPO"
    });
    const formLoadPointError = ref({ errors: {} });
    const formUserAccount = ref([]);
    const formUserAccountError = ref([]);

    const showButtonLoadPoint = ref(true);
    const registerPointSuccess = ref(false);
    const showFileLoadPoint = ref(true);
    const productForExchange = ref([]);
    const shopRegisterPointSuccessData = ref({});
    const pointActivityData = ref([]);

    const config = {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    };
    const selectedProductForExchange = e => {
      console.log(
        e.target.options[e.target.options.selectedIndex].text,
        "producto"
      );
      productForExchangeSelectedText.value =
        e.target.options[e.target.options.selectedIndex].text;
    };
    const uploadImageVoucher = event => {
      formLoadPoint.value.voucher = event.target.files[0];
    };

    const onSubmitLoadPoint = () => {
      isLoading.value = true;
      let formData = new FormData();
      //debugger
      if (formLoadPoint.value.date_register) {
        formLoadPoint.value.date_register = formLoadPoint.value.date_register
          .toISOString()
          .split("T")[0];
      }

      for (var key in formLoadPoint.value) {
        formData.append(key, formLoadPoint.value[key]);
      }
      console.log(formLoadPoint, "data");
      API.post("/api/point-activity", formData, config)
        .then(response => {
          isLoading.value = false;
          registerPointSuccess.value = true;
          setShopRegisterPointSuccessData();
        })
        .catch(error => {
          isLoading.value = false;
          formLoadPointError.value = error.response.data;
        });
    };

    const setShopRegisterPointSuccessData = () => {
      shopRegisterPointSuccessData.value = {
        shop: store.state.dataToken.name,
        membership: store.state.dataToken.code_user,
        fullName: userData.value.first_name + " " + userData.value.last_name,
        productForExchange: productForExchangeSelectedText.value,
        meter: formLoadPoint.value.meter,
        point: userData.value.total_point,
        date_register: formLoadPoint.value.date_register,
        voucher: formLoadPoint.value.voucher.name,
        status: "Pendiente"
      };
    };

    const getUser = () => {
      API.get("/api/user?query=" + formUserSearch.value.query + "&shop_exclude")
        .then(response => {
          formUserSearch.value.list = response.data;
          showFileLoadPoint.value = false;
        })
        .catch(error => {
          formUserAccountError.value = error.response.data;
        });
    };
    const loadPoint = userParam => {
      formLoadPoint.value.user = userParam.id;
      userData.value = userParam;
      showButtonLoadPoint.value = false;
    };

    const getProductForExchange = () => {
      API.get("/api/product-for-exchange").then(response => {
        productForExchange.value = response.data;
      });
    };

    const getPointActivity = () => {
      API.get("/api/point-activity").then(response => {
        pointActivityData.value = response.data;
      });
    };

    const getCssStatus = v => {
      let css = {
        REJEC: "reject_status_point",
        APPRO: "approved_status_point",
        PENDI: "pending_status_point"
      };
      return css[v];
    };

    return {
      isLoading,
      fullPage,
      router,
      formUserSearch,
      formUserAccount,
      formUserAccountError,
      getUser,
      showButtonLoadPoint,
      loadPoint,
      formLoadPointError,
      formLoadPoint,
      showFileLoadPoint,
      productForExchange,
      getProductForExchange,
      uploadImageVoucher,
      onSubmitLoadPoint,
      store,
      registerPointSuccess,
      shopRegisterPointSuccessData,
      selectedProductForExchange,
      pointActivityData,
      getPointActivity,
      getCssStatus
    };
  },
  mounted() {
    this.getProductForExchange();
    this.getPointActivity();
  }
};
</script>

<style scoped>
.page-content {
  /* max-width: 700px;  */
  max-width: 80%;
  margin: 32px auto;
  /* padding: 32px;  */
  background: #fff;
}
a {
  color: #21d4fd;
  transition: all 0.3s;
}
a:hover {
  color: #b721ff;
}

.tabbed {
  /* overflow-x: hidden; */ /* so we could easily hide the radio inputs */
  margin: 32px 0;
  padding-bottom: 16px;
  /* border-bottom: 1px solid #ccc; */
}

.tabbed [type="radio"] {
  /* hiding the inputs */
  display: none;
}

.tabs {
  display: flex;
  align-items: stretch;
  list-style: none;
  padding: 0;
  border-bottom: 1px solid #ccc;
}
.tab > label {
  display: block;
  margin-bottom: -1px;
  padding: 12px 15px;
  border: 1px solid #ccc;
  background: #eee;
  color: #666;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  cursor: pointer;
  transition: all 0.3s;
}
.tab:hover label {
  border-top-color: #333;
  color: #333;
}

.tab-content {
  display: none;
  color: #777;
}

/* As we cannot replace the numbers with variables or calls to element properties, the number of this selector parts is our tab count limit */
.tabbed [type="radio"]:nth-of-type(1):checked ~ .tabs .tab:nth-of-type(1) label,
.tabbed [type="radio"]:nth-of-type(2):checked ~ .tabs .tab:nth-of-type(2) label,
.tabbed [type="radio"]:nth-of-type(3):checked ~ .tabs .tab:nth-of-type(3) label,
.tabbed [type="radio"]:nth-of-type(4):checked ~ .tabs .tab:nth-of-type(4) label,
.tabbed
  [type="radio"]:nth-of-type(5):checked
  ~ .tabs
  .tab:nth-of-type(5)
  label {
  border-bottom-color: #fff;
  /* border-top-color: #B721FF; */
  background: #fff;
  color: #222;
}

.tabbed [type="radio"]:nth-of-type(1):checked ~ .tab-content:nth-of-type(1),
.tabbed [type="radio"]:nth-of-type(2):checked ~ .tab-content:nth-of-type(2),
.tabbed [type="radio"]:nth-of-type(3):checked ~ .tab-content:nth-of-type(3),
.tabbed [type="radio"]:nth-of-type(4):checked ~ .tab-content:nth-of-type(4) {
  display: block;
}
</style>